import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useGetMyJobsQuery, useDeleteJobMutation } from '../../api/dashboardApiSlice'
import useJobUrl from '../../../../common/hooks/useJobUrl';
import Accordion from '../../../../components/JobAccordion/JobAccordion';
import SortFilter from '../SortFilter/SortFilter';
import { useSelector } from 'react-redux';

import styles from './MyJobs.module.scss'

function MyJobs() {

    const dashboard = useSelector(state => state.dashboard)
    const currentEmployer = dashboard.employer?.value

    const [searchInput, setSearchInput] = useState('');
    const [selectedFilterOption, setSelectedFilterOption] = useState('');
    const [selectedSortOption, setSelectedSortOption] = useState('');

    let args = {
        currentEmployer,
        filter: selectedFilterOption || '',
        sort: selectedSortOption || '',
        search: searchInput || ''
    }
    const { data, error, isLoading, isFetching, refetch } = useGetMyJobsQuery(args);

    const [deleteJob, { isLoading: isJobDeleting, isError: isJobDeleteError, isSuccess: isJobDeleteSuccess }] = useDeleteJobMutation();

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    const jobUrl = useJobUrl()

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    const [activeIndex, setActiveIndex] = useState(null);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        refetch();
    }, [selectedFilterOption, selectedSortOption, searchInput]);


    if (data === undefined || (data.length === 0 && !isLoading && !isFetching)) {
        return (<>
            <SortFilter sort={{ selectedSortOption, setSelectedSortOption }} filter={{ selectedFilterOption, setSelectedFilterOption }} search={{ searchInput, setSearchInput }} />
            <div className={styles.noJobs}><p>No Jobs Found!</p></div>
        </>
        )
    }

    const handleDelete = async (slug, e) => {
        if (window.confirm('Are you sure you want to delete this job?')) {
            await deleteJob(slug);
        }
    }


    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const getSiteNames = () => {
        return [
            'Lgbtjobs',
            'Bmejobs',
            'Disabilityjob',
            'Neurodiversityjobs',
        ]
    };

    return (
        <>
            <SortFilter sort={{ selectedSortOption, setSelectedSortOption }} filter={{ selectedFilterOption, setSelectedFilterOption }} search={{ searchInput, setSearchInput }} />
            {
                <div className={styles.TableWraper}>
                    <div className={`${styles.table} table`}>
                        <div className={`row ${styles.heading}`}>
                            <div className='col-3'><p>Title</p></div>
                            <div className='col'><p>Date Posted</p></div>
                            <div className='col'><p>Expiry Date</p></div>
                            <div className='col-4'>
                                <p>Views</p>
                                <div className={`${styles.countRow}`}>
                                    {
                                        getSiteNames().map((siteName, index) => {
                                            return (<div className={`${styles.countParent} col ${styles[siteName]}`} key={index}>{siteName}</div>)
                                        })
                                    }
                                </div>
                            </div>
                            <div className='col'><p>Status</p></div>
                        </div>
                        {data.map((job, index) => {
                            const viewsKeys = Object.keys(job).filter(key => key.startsWith('views_on_'));
                            let createdDate = new Date(job.created_at)
                            let expiryDate = job.expiry_date != null ? new Date(job.expiry_date).toLocaleDateString('en-US', dateOptions) : '-';
                            const status = styles[job.status.toLowerCase()];
                            if (!isMobile) {
                                return (
                                    <div className={`row ${styles.listing} ${styles.desktopListing}`} key={job.id}>
                                        <div className='col-3' dangerouslySetInnerHTML={{ __html: `${job.title}` }}></div>
                                        <div className='col' >{createdDate.toLocaleDateString('en-US', dateOptions)}</div>
                                        <div className='col'>{expiryDate}</div>
                                        <div className='col-4'>
                                            <div className={`${styles.countRow} row`}>
                                                {viewsKeys.map((key, index) => (
                                                    <div className={`${styles.count} col`} key={index}>
                                                        {job[key]}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='col'><span className={status}>{job.status}</span></div>
                                        <div className={`col-12 ${styles.hoverSection}`}>
                                            <div dangerouslySetInnerHTML={{ __html: `${job.title}` }}></div>
                                            <div className={styles.links}>
                                                <Link className={styles.edit} to={`/job/${job.id}/edit`}>Edit</Link>
                                                <Link className={styles.view} target='_blank' to={jobUrl(job.slug)}>View</Link>
                                                <button className={styles.delete} onClick={(e) => { handleDelete(job.slug, e) }} to='#'>Delete</button>
                                            </div>
                                        </div>
                                    </div>

                                )
                            }
                            else {
                                return (
                                    <div className={`row ${styles.listing} `} key={job.id}>
                                        <Accordion
                                            key={job.id}
                                            job={job}
                                            isActive={index === activeIndex}
                                            onClick={() => handleAccordionClick(index)}
                                            jobUrl={jobUrl}
                                            handleDelete={handleDelete}
                                            viewsKeys={viewsKeys}
                                        />
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            }
        </>
    )
}

export default MyJobs
