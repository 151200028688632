import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FaPlay } from "react-icons/fa";
import './TextToSpeech.scss';

const TextToSpeech = () => {
  const [speaking, setSpeaking] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [selectedText, setSelectedText] = useState('');
  const [voices, setVoices] = useState([]);
  const site = useSelector(state => state.site);
  const buttonRef = useRef(null);
  let hasEnabledVoice = false;

  const isIPhoneOrIPad = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const isFirefox = () => {
    return /Firefox/.test(navigator.userAgent);
  };

  const populateVoiceList = () => {
    const availableVoices = window.speechSynthesis.getVoices();
    if (availableVoices.length === 0) {
      setTimeout(populateVoiceList, 100);
    } else {
      setVoices(availableVoices);
    }
  };

  useEffect(() => {
    populateVoiceList();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = populateVoiceList;
    }
  }, []);

  const handleSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const buttonTop = rect.bottom - bodyRect.top + 5;
      const buttonLeft = rect.left + rect.width / 2;
      setButtonPosition({ top: buttonTop, left: buttonLeft });
      const text = selection.toString().trim();
      setSelectedText(text);
    } else {
      setSelectedText('');
    }
  };

  const handlePlayButtonClick = () => {
    if (selectedText) {
      readText(selectedText);
    }
  };

  const readText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);

    let selectedVoice;

    if (isFirefox()) {
      selectedVoice = voices.find(voice => voice.lang === 'en-GB-SCOTLAND' && voice.name === 'English (Scotland)+Steph ') || voices[0.1];
    } else if (isIPhoneOrIPad()) {
      selectedVoice = voices.find(voice => voice.voiceURI === "com.apple.voice.compact.en-US.Samantha" && voice.name === "Samantha" && voice.lang === "en-US");
      if (!selectedVoice) {
        selectedVoice = voices.find(voice => voice.lang === 'en-GB') || voices[0];
      }
    } else {
      selectedVoice = voices.find(voice => voice.lang === 'en-GB' && voice.name.includes('Female')) || voices.find(voice => voice.lang === 'en-GB') || voices[0];
    }

    utterance.voice = selectedVoice;
    utterance.volume = 1;
    utterance.rate = 1;
    utterance.pitch = 1;

    utterance.onend = () => {
      setSpeaking(false);
      if (isIPhoneOrIPad()) {
        window.speechSynthesis.cancel();
      }
    };

    utterance.onerror = (e) => {
      // console.error('SpeechSynthesisUtterance.onerror', e);
      setSpeaking(false);
    };

    window.speechSynthesis.speak(utterance);
    setSpeaking(true);
  };

  useEffect(() => {
    const enableVoice = () => {
      if (hasEnabledVoice) return;
      const lecture = new SpeechSynthesisUtterance('');
      lecture.volume = 1;
      speechSynthesis.speak(lecture);
      hasEnabledVoice = true;
    };

    document.addEventListener('click', enableVoice);

    return () => {
      document.removeEventListener('click', enableVoice);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelection);

    return () => {
      document.removeEventListener('selectionchange', handleSelection);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        if (speaking) {
          window.speechSynthesis.cancel();
          setSpeaking(false);
        }
        setSelectedText('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [speaking]);

  return (
    <>
      {selectedText && (
        <button
          ref={buttonRef}
          className={`speech-button ${speaking ? 'speaking' : ''}`}
          style={{ top: buttonPosition.top, left: buttonPosition.left, backgroundColor: site.primaryColor }}
          onClick={handlePlayButtonClick}
          disabled={speaking}
        >
          <FaPlay />
        </button>
      )}
    </>
  );
};

export default TextToSpeech;
